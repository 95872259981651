<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <div class="table-box">
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button @click="visible = true" class="bg-gradient">新建分类</el-button>
          </el-row>
        </div>
        <el-table ref="table" v-loading="loading" :data="tableData" :stripe="true" border fit
                  lazy row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column prop="name" label="分类名称"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="level" label="等级"></el-table-column>
          <el-table-column prop="sortNo" label="排序"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button @click="handleAdd(scope.row)" type="text" v-if="scope.row.level < 3">新建下级</el-button>
              <el-button @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="新建分类" :visible.sync="visible" width="550px" close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name" class="width-280"></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sortNo">
          <el-input v-model="ruleForm.sortNo" class="width-280"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button class="bg-gradient" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getList, createCategory, delCategory } from "@/api/shop/goods/category";
import { mapState } from 'vuex';
export default {
  name: 'classify',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['商品中心'], ['商品分类']
      ]),
      loading: false,
      tableData: [],
      visible: false,
      page: 1,
      limit: 1,
      ruleForm: {
        name: '',
        sortNo: '1',
        level: '1'
      },
      rules: {
        name: [{ required: true, message: '分类名称不能为空！' }],
        sortNo: [{ required: true, message: '分类等级不能为空！' }]
      },
      initForm: {},
      action: 'create'
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.initForm = { ...this.ruleForm }
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getCategory()
  },
  methods: {
    // 获取商品分类列表
    getCategory() {
      const params = { hotelId: this.hotelInfo.id }
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getList(params).then(({ success, total, records }) => {
        if (!success) return;
        this.total = total
        this.tableData = records
      })
    },
    // 新增商品分类
    handleAdd({ level, id }) {
      this.action = 'create'
      this.ruleForm.level = level + 1
      this.ruleForm.parentId = id
      this.visible = true
    },
    // 编辑商品分类
    handleEdit({ id, name, sortNo }) {
      this.action = 'update'
      this.ruleForm.id = id
      this.ruleForm.name = name
      this.ruleForm.sortNo = sortNo
      this.visible = true
    },
    // 保存商品分类
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const params = { ...this.ruleForm,hotelId: this.hotelInfo.id }
        createCategory(params, this.action).then(({ success }) => {
          if (!success) return;
          this.getCategory()
          this.handleClose()
          this.$message({ message: '保存商品成功！', type: 'success' })
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.visible = false
      this.action = 'create'
      this.ruleForm = { ...this.initForm }
    },
    // 删除商品分类
    handleDel(id) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        const query = `/${ id }`
        delCategory(query).then(({ success }) => {
          if (!success) return;
          this.getCategory()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getCategory()
    }
  }
}
</script>
